import * as React from "react"
import Seo from "~components/Seo"
import { css } from '@emotion/react'
// import Section from '~components/Section'

// markup
const NotFoundPage = () => {
  return ( 
    <>
      <Seo title="404 Not found" />
			<div css={css`
				text-align: center;
				padding: 50px 0;
				min-height: 50vh;
				display: flex;
				align-items: center;
				justify-content: center;
			`}>
				<div>
					<h1>404</h1>
					<p>Page not found</p>
				</div>
			</div>
    </>
  )
}

export default NotFoundPage
